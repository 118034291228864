import React, { useRef, useState } from "react";
import { CopyIcon } from "../../../IconTheme";


const CopyValue = ({ val }) => {
	const [value, setValue] = useState(val || "0x0000000000000000000000000000000000000000");
	const [copy, setCopy] = useState(false);

	const ref = useRef();
	const handleClick = () => {
		navigator.clipboard.writeText(value);
		setInterval(() => {
			setCopy(false);
		}, 1000);
	};
	return (
		<>
			<input
				type="text"
				value={value}
				readOnly
				style={{ width: "0", flexGrow: "1" }}
				ref={ref}
			/>
			<button
				onClick={() => {
					handleClick();
					setCopy(!copy);
				}}
				className="text-white"
			>
				{copy ? "Copied" : <CopyIcon />}
			</button>
		</>
	);
};

export default CopyValue;
