import React from "react";
import { Link } from "react-router-dom";
import { Envelop, MapMarker, PhoneIcon } from "../IconTheme";
import SocialIcons from "./SocialIcons";
// import { BrightnessIcon, Envelop, GlobeIcon } from "../../icon/IconTheme";

const Footer = () => {
	return (
		<footer>
			<FooterTop />
			<FooterBottom />
		</footer>
	);
};
const data = [
	{
		link: "#",
		name: "Docs",
	},
	{
		link: "https://github.com/Antexpad",
		name: "Github",
	},

];
const data2 = [
	{
		link: "#",
		name: "Branding Kit",
	},
	
];
const data3 = [
	{
		link: "#",
		name: "Marketing",
	},
	
];
export const FooterTop = () => {
	return (
		<>
			<div className="footer-top">
				<div className="container">
					<div className="footer-wrapper">
						<div className="footer-widget widget-link">
							<h6 className="title">Developments</h6>
							<LinkList data={data} />
						</div>
						<div className="footer-widget widget-link">
							<h6 className="title">Branding</h6>
							<LinkList data={data2} />
						</div>
						<div className="footer-widget widget-link">
							<h6 className="title">Partnerships</h6>
							<LinkList data={data3} />
						</div>
						<div className="footer-widget widget-contact mx-lg-auto">
							<h6 className="title">Get in Touch</h6>
							<ul>
								{/* <li>
									<MapMarker />
									<span>8819 Ohio St. South Gate, CA 90280</span>
								</li> */}
								<li>
									<Envelop />
									<span>support@antexpad.com</span>
								</li>
								<li>
									<PhoneIcon />
									<span>@antexpad</span>
								</li>
							</ul>
						</div>
						<div className="footer-widget">
							<SocialIcons />
							<div className="mt-4">Follow our social media.</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export const LinkList = ({ data }) => {
	return (
		<>
			<ul>
				{data &&
					data.map(({ link, name }, i) => (
						<li key={i}>
							<Link to={link}>{name}</Link>
						</li>
					))}
			</ul>
		</>
	);
};
export const FooterBottom = () => {
	return (
		<>
			<div className="footer-bottom">
				<div className="container">
					<div className="footer-bottom-wrapper">
						<ul className="links">
							<li>
								<Link to="#">Privacy Policy</Link>
							</li>
							<li>
								<Link to="#">Privacy Policy</Link>
							</li>
							<li>
								<Link to="#">Privacy Policy</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};
export default Footer;
