import React from "react";
import { Link } from "react-router-dom";
import { Instagram, TelegramFill, TwitterFill } from "../IconTheme";

const social_icons = [
	{
		icon: <TwitterFill />,
		link: "https://www.twitter.com/antexpad",
	},
	{
		icon: <TelegramFill />,
		link: "https://t.me/antexpad",
	},
];

const SocialIcons = () => {
	return (
		<>
			<ul className="social-icons">
				{social_icons &&
					social_icons.map(({ icon, link }, i) => (
						<li key={i}>
							<a href={link}>{icon}</a>
						</li>
					))}
			</ul>
		</>
	);
};

export default SocialIcons;
