import React, { useState } from "react";
import AntexCard from "../../../components/AntexCard";
import Pagination from "../../../components/Pagination";
import FilterHeader from "./FilterHeader";
import { Link } from "react-router-dom";
const antexData = [
	{
		audit: true,
		kyc: false,
		idoName : "Antex Presale",
		status : "upcoming",
		description : "Antex Provides an innovative platform for fund-raising on Hepton L2 blockchain.",
		hardcap : "500000",
		liquidity : "50",
		softcap : "25000",
		whitelist : "100",
		totalBuyers : "1",
		twitter : "https://twitter.com/antexpad",
		telegram : "https://t.me/antexpad	",
		website : "https://antexpad.com",
		ca : "0x9aF8669A5D71e205b1b980125Ac59081B06A812a",
	},
];
const AntexSection = () => {
	const [data, setData] = useState(antexData);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(6);
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;

	return (
		<section className="py-5">
			<div className="container">
				<div className="mb-4">
					<FilterHeader />
				</div>
				<div className="row g-4 antext-row">
					
					{data
						?.map((item, i) => 
						<Link to={`/ht/ido/${data[i].ca}`}>
						<AntexCard key={i} data={data[i]} />
						</Link>
						)
						.slice(indexOfFirstPost, indexOfLastPost)}
				</div>
				<Pagination
					data={data}
					postsPerPage={postsPerPage}
					setCurrentPage={setCurrentPage}
				/>
			</div>
		</section>
	);
};
export default AntexSection;
