import React, { useEffect, useState } from "react";
import PresaleMarquee from "../../components/PresaleMarquee";
import Stake from "./components/Stake";
import StakeLeaderBoard from "./components/StakeLeaderBoard";
import StakersStatistics from "./components/StakersStatistics";

const Staking = () => {
	
	const [stake, setStake] = useState("stake");

	return (
		<>
			<PresaleMarquee />
			<StakersStatistics />
			<div className="container pb-5">
				<div className="row g-lg-0 g-4">
					<div className="col-lg-6 pe-lg-4 pe-xl-5">
						<StakeLeaderBoard />
					</div>
					<div className="col-lg-6">
						<div className="stake-withdraw-card">
							<ul className="stake-withdraw-card-menu">
								<li
									className={stake === "stake" ? "active" : ""}
									onClick={() => setStake("stake")}
								>
									Stake
								</li>
								<li
									className={stake === "withdraw" ? "active" : ""}
									onClick={() => setStake("withdraw")}
								>
									Withdraw
								</li>
							</ul>
							<Stake stake={stake} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Staking;
