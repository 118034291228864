import { useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Antex from "./pages/Antex/Antex";
import CreateIDO from "./pages/CreateIDO/CreateIDO";
import Home from "./pages/Home/Home";
import {Sugar} from 'react-preloaders';
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";


import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import Staking from "./pages/Staking/Staking";



function App() {

	const customChain = {
		id: 404,
		name: 'tHepton',
		network: 'tHepton',
		nativeCurrency: {
		  decimals: 18,
		  name: 'Hepton',
		  symbol: 'HTE',
		},
		rpcUrls: {
		  default: { http: ['https://testnet.hepton.io'] },
		},
		blockExplorers: {
		  etherscan: { name: 'HeptonScan', url: 'https://testnet.heptonscan.com' },
		  default: { name: 'HeptonScan', url: 'https://testnet.heptonscan.com' },
		},
		contracts: {
		  multicall3: {
			address: '0xca11bde05977b3631167028862be2a173976ca11',
			blockCreated: 11907934,
		  },
		},
	  }


const chains = [customChain];
	// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "d3873ed18b4ac26cf6f5dffdb2fe9cbd" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);


	const Wrapper = ({ children }) => {
		const location = useLocation();
		useLayoutEffect(() => {
			document.documentElement.scrollTo(0, 0);
		}, [location.pathname]);
		return children;
	};
	return (
		<BrowserRouter>
			<Wrapper>
				   <WagmiConfig client={wagmiClient}>
				<Header />
				
				<Routes>
					<Route path="/" element={<Home />} />
					
					 {/* <Route path="/:chain/ido/:ido" element={<Antex />} /> */}
					{/*<Route path="/create-ido" element={<CreateIDO />} /> */}
					<Route path="/staking" element={<Staking />} />
				</Routes>
				<Footer />
				
				<Web3Modal
        projectId="d3873ed18b4ac26cf6f5dffdb2fe9cbd"
		
        ethereumClient={ethereumClient}
      />
				</WagmiConfig>
			</Wrapper>
		</BrowserRouter>
	);
}

export default App;
