import React from "react";
import { Link } from "react-router-dom";
const RatesCount = (props) => {
	return (
		<>
			<div
				className="d-flex flex-wrap rates-counts justify-content-center"
				style={{ columnGap: "17px", rowGap: "15px" }}
			>
				<Link onClick={() => {
					props.setStakeAmount(props.balance * 0.25);
				}}>25%</Link>
				<Link onClick={() => {
					props.setStakeAmount(props.balance * 0.50);
				}}>50%</Link>
				<Link onClick={() => {
					props.setStakeAmount(props.balance * 0.75);
				}}>75%</Link>
				<Link onClick={() => {
					props.setStakeAmount(props.balance );
				}}>100%</Link>
			</div>
		</>
	);
};

export default RatesCount;
