import React from "react";
import { getTotalStaked } from "../../../hash/functions/Staking";
import { useState } from "react";

const StakersStatistics = () => {
	const [totalStaked, setTotalStaked] = useState(0);
	const init = async () => {
		const totalStaked = Number(await getTotalStaked());
		console.log(totalStaked);
		
		setTotalStaked(totalStaked.toFixed(2));
	}
	init();
	return (
		<div className="py-4 py-sm-5">
			<div className="container">
				<div className="statistics-wrapper">
					<StatisticsCard title="27 %" subtitle="APR" />
					{/* <StatisticsCard title="---" subtitle="Stakers" /> */}
					<StatisticsCard title={`${totalStaked} ANT`} subtitle="Staked" />
				</div>
			</div>
		</div>
	);
};

const StatisticsCard = ({ title, subtitle }) => (
	<div className="statistics-card">
		<div className="subtitle">{subtitle}</div>
		<div className="title">{title}</div>
	</div>
);

export default StakersStatistics;
