import React from "react";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import BuyRange from "../../Antex/components/BuyRange";
import RatesCount from "../../Antex/components/RatesCount";
import { stakingABI } from "../../../hash/abi/staking";
import { useAccount } from "wagmi";
import { getBalance } from "../../../hash/functions/Token";
import { useSigner } from 'wagmi'
import { AntexABI } from "../../../hash/abi/token";
import { checkAllowance } from "../../../hash/functions/Token";
import { getStakingBalance } from "../../../hash/functions/Staking";
import { getTotalStaked , getEarnings} from "../../../hash/functions/Staking";
import * as alerts from "../../../hash/alerts/alert.js";
import { useWeb3Modal } from "@web3modal/react";
import { Lines } from 'react-preloaders2';






const Stake = ({ stake }) => {
	const stakingContract = "0x2B5eDdd4402132e2a2004C665a5b071fA3c2abb7";
	const antexToken = "0xFb5e97E0a6443Db91ecB2c9F7Fdf7dfD2Ada0067";
	const { data: signer, isError, isLoading } = useSigner()
	const [stakeAmount, setStakeAmount] = useState(100);
	const [balance, setBalance] = useState(0);
	const [stakes, setStakes] = useState(0);
	const { address } = useAccount();
	const [allowance, setAllowance] = useState(0);
	const [totalStaked, setTotalStaked] = useState(0);
	const [earnings, setEarnings] = useState(0);

	<Lines color={'#f7f7f7'} />;
	const stakeHandler = async () => {
		let s = stakeAmount;
		try{
		const staking = new ethers.Contract(stakingContract, stakingABI, signer);
		const tx = await staking.stake(ethers.utils.parseUnits(stakeAmount.toString()), "0x0000000000000000000000000000000000000000", {gasLimit: 300000});
		const receipt = await tx.wait();
		if (receipt.blockNumber) {
			setStakes((stakes) => {
				return stakes + s;
			})
			init();
			alerts.success("Staking Successful")
		}
	} catch (e) {
		console.log(e.error.data.message , "error")
		alerts.error(e.error.data.message)
	
		}
	}
	
	const approveHandler = async () => {
		try{
		const token = new ethers.Contract(antexToken, AntexABI, signer);
		const tx = await token.approve(stakingContract, ethers.utils.parseUnits(("100000000000000000000000000")),{gasLimit: 300000, gasPrice: 20000000000});
		const receipt = await tx.wait();
		if (receipt.blockNumber) {
			init();
			alerts.success("Successful")
		}
	} catch (e) {
		console.log(e.error.data.message , "error")
		alerts.error(e.error.data.message)
	
		}
	}
	const withdrawHandler = async () => {
		const staking = new ethers.Contract(stakingContract, stakingABI, signer);
		const tx = await staking.unstake(ethers.utils.parseUnits(stakeAmount.toString()), {gasLimit: 300000});
		const receipt = await tx.wait();
		alerts.loading("Withdrawing")
		if (receipt.confirmations > 0) {
			alerts.success("Withdrawal Successful");
			init();
		}
	}

	const withdrawEarningsHandler = async () => {
		const staking = new ethers.Contract(stakingContract, stakingABI, signer);
		const tx = await staking.withdrawEarnings({gasLimit: 300000});
		const receipt = await tx.wait();
		alerts.loading("Withdrawing")
		if (receipt.confirmations > 0) {
			alerts.success("Withdrawal Successful");
			init();
		}
	}

	

	const init = async () => {
		const b = await getBalance(address);
		setBalance(b);
		const allowance = await checkAllowance(antexToken, address , stakingContract);
		setAllowance(allowance);
		setStakes(await getStakingBalance(address))
		setTotalStaked(await getTotalStaked());
		setEarnings(await getEarnings(address));
		
	}
	const { isOpen, open, close } = useWeb3Modal();

	const stakeButton = () => {

		if (!address) {
			return {
				title : "Connect Wallet",
				func : open
			}
		} else if (parseInt(allowance) === 0){
			return {
				title : "Enable ANT-P",
				func : approveHandler
			} 
		} else {
			return {
				title : "Stake",
				func : stakeHandler
			}
		}
	}

	const sButton = stakeButton();
	useEffect(() => {
		init();
	}, [init]);

	return (
		<>
			<div className="__stake">
				{stake === "stake" ? (
					<>
						<div className="info-list">
							<ul>
								<li>
									<span>Balance</span>
									<span>{balance} ANT-P</span>
								</li>
								<li>
									<span>Stake Balance</span>
									<span>{stakes} ANT-P</span>
								</li>
								<li>
									<span>Earnings (Updated Daily)</span>
									<span>{earnings}</span>
								</li>
							</ul>
						</div>
						<BuyRange stakevalue = {setStakeAmount}  stakeBoxValue = {stakeAmount}/>
						<RatesCount balance={balance} setStakeAmount={setStakeAmount}/>
						{/* <Duration /> */}
						<div className="text-center">
							<button onClick={sButton.func}className="cmn-btn btn--lg text-capitalize">
								{sButton.title}
							</button>
						</div>
						<div className="info-list transparent mt-0">
							<ul>
								<li>
									<span>Fee</span>
									<span>0.02 ANT</span>
								</li>
								<li>
									<span>Transaction Fee</span>
									<span>~ 0.0006 HTE</span>
								</li>
							</ul>
						</div>
					</>
				) : (
					<>
						{/* <Duration /> */}
						<div className="info-list">
							<ul>
								<li>
									<span>Stake Balance</span>
									<span>{stakes} ANT-P</span>
								</li>
								<li>
									<span>Earnings (Updated Daily)</span>
									<span>{earnings}</span>
								</li>
							</ul>
						</div>
						<BuyRange stakevalue = {setStakeAmount}  stakeBoxValue = {stakeAmount}/>
						<RatesCount balance={stakes} setStakeAmount={setStakeAmount}/>
						<div className="text-center">
							<button onClick={withdrawHandler} className="cmn-btn btn--lg text-capitalize">
								Unstake
							</button>

							
						</div>
						<div className="info-list transparent mt-0">
							<ul>
								<li>
									<span>Fee</span>
									<span>0.02 ANT</span>
								</li>
								<li>
									<span>Transaction Fee</span>
									<span>~ 0.0006 HTE</span>
								</li>
								<button onClick={withdrawEarningsHandler}className="cmn-btn btn--lg text-capitalize">
								Withdraw Earnings
							</button>
							</ul>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export const Duration = () => {
	return (
		<>
			<div className="duration-wrapper">
				<div className="duration-item">
					<div className="subtxt">Duration</div>
					<div className="subtitle">7 DAYS</div>
				</div>
				<div className="duration-item">
					<div className="subtxt">Duration</div>
					<div className="subtitle">30 DAYS</div>
				</div>
				<div className="duration-item">
					<div className="subtxt">Duration</div>
					<div className="subtitle">60 DAYS</div>
				</div>
			</div>
		</>
	);
};

export default Stake;
