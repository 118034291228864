import React from "react";
import { Link } from "react-router-dom";
import img from "../assets/img/antext-icon.svg";
import {
	ChartIcon,
	GlobeFill,
	LockIcon,
	TelegramFill,
	TwitterFill,
	UserBookmark,
	UserCheck,
} from "../IconTheme";
import AntexSocialIcons from "./AntexSocialIcons";
import CountdownCard from "./CountdownCard";
import Progress from "./Progress";
const AntexCard = (d) => {
	const info = (d.data)
	return (
		<div className="col-lg-4 col-md-6">
			<div className="antex-card">
				<div
					className="d-flex flex-wrap justify-content-end"
					style={{ gap: "7.89px" }}
				>
					<span className={`antex-badge ${info.audit ? "active" : "inactive"}`}>
						Audit
					</span>
					<span className={`antex-badge ${info.kyc ? "active" : "inactive"}`}>
						Kyc
					</span>
				</div>
				<div className="antex-top">
					<img src={img} alt="" />
					<div className="info">
						<h5 className="title">
							<Link to={`/ht/ido/${info.ca}`} className="text-white">
								{info.idoName}
							</Link>
						</h5>
						<div className="d-flex flex-wrap align-items-center">
							<span className="antex-badge active me-3">{info.status}</span>
							<ChartIcon />
						</div>
					</div>
				</div>
				<div className="antex-post-meta px-1">
					<Link className="antex-post-meta-item" to="#">
						<LockIcon /> {info.liquidity}%
					</Link>
					<Link className="antex-post-meta-item" to="#">
						<UserCheck /> {info.whitelist}/200
					</Link>
					<Link className="antex-post-meta-item" to="#">
						<UserBookmark /> {info.totalBuyers}
					</Link>
				</div>
				<div className="text px-1">
					{info.description}
				</div>
				<ul className="antex-softcap px-1">
					<li>
						<span>Softcap :</span>
						<span>{info.softcap} USDT</span>
					</li>
					<li>
						<span>Hardcap :</span>
						<span>{info.hardcap} USDT</span>
					</li>
					<li>
						<span>Liquidity :</span>
						<span>{info.liquidity}%</span>
					</li>
				</ul>
				<div className="antex-progress mx-1">
					<div className="antex-progress-top d-flex justify-content-between">
						<span>Round 1</span>
						<CountdownCard targetDate={`January 17, 2023 23:00:00`} />
					</div>
					<Progress title="0%" progress="0" />
					<div className="antex-progress-bottom d-flex justify-content-between">
						<span> 0 USDT</span>
						<span>50000 USDT</span>
					</div>
				</div>
				<AntexSocialIcons  links={info}/>
			</div>
		</div>
	);
};

export default AntexCard;
