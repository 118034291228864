import { ethers } from "ethers";
import { AntexABI } from "../abi/token";

const provider = new ethers.providers.JsonRpcProvider('https://testnet.hepton.io');

export const getBalance = async (address) => {
    try{
  const contract = new ethers.Contract("0xFb5e97E0a6443Db91ecB2c9F7Fdf7dfD2Ada0067", AntexABI, provider);
  const balance = await contract.balanceOf(address);
  return ethers.utils.formatEther(balance);
    } catch (e){
        console.log(e);
        return 0;
    }
}

export const getTokenName = async () => {
    const contract = new ethers.Contract("0xFb5e97E0a6443Db91ecB2c9F7Fdf7dfD2Ada0067", AntexABI, provider);
    const name = await contract.name();
    return name;
}
export const getTokenSymbol = async () => {
    const contract = new ethers.Contract("0xFb5e97E0a6443Db91ecB2c9F7Fdf7dfD2Ada0067", AntexABI, provider);
    const symbol = await contract.symbol();
    return symbol;
}
export const getTokenSupply = async () => {
    const contract = new ethers.Contract("0xFb5e97E0a6443Db91ecB2c9F7Fdf7dfD2Ada0067", AntexABI, provider);
    const supply = await contract.totalSupply();
    return supply;
}

export const checkAllowance = async (address, owner, contract_to_check) => {
    const contract = new ethers.Contract(address, AntexABI, provider);
    const allowance = await contract.allowance(owner, contract_to_check);
    return allowance;
}