import React, { useEffect } from "react";
import CopyValue from "../../Antex/components/CopyValue";
import { useState } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { useSigner } from 'wagmi';
import * as alerts from "../../../hash/alerts/alert.js";


const query = `
{
	users (first: 5, orderBy:stakeAmountMonth,orderDirection:desc){
	  id
	  stakeAmountMonth
	}
  } `

const data = [];

const getData = async () => {
	try {
const data = await fetch("https://graph.antexpad.com/subgraphs/name/harmony/blocks", {
	method: "POST",
	body: JSON.stringify({ query }),
})
	const res = await data.json();
	console.log(res.data['users'])
	return res.data['users'];
	
	} catch (e) {
		console.log(e)
	}
}




const StakeLeaderBoard = () => {
	const [stakeData , setStakeData] = useState([]);
	const { address } = useAccount();
	const [claimed, setClaimed] = useState(false);
	const { data: signer, isError, isLoading } = useSigner()
	let airdropABI = [
		{
			"inputs": [],
			"name": "claim",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "isClaimed",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		}
	];
	const airdropAddress = "0x94a5224133dBcae4111ce47b61319265D6C23fa7";
	
	const claimHandler = async () => {
		const airdropContract = new ethers.Contract(airdropAddress, airdropABI, signer);
		const tx = await airdropContract.claim({ gasLimit: 300000});
		const receipt = await tx.wait();
		if (receipt.blockNumber) {
		alerts.success("Claim Successful")
		setClaimed(true);
		}
	}
	

	const isClaimed = async () => {
		const airdropContract = new ethers.Contract(airdropAddress, airdropABI, signer);
		const isClaimed = await airdropContract.isClaimed(address);
		console.log(isClaimed, "isClaimed")
		if (isClaimed) {
			
			setClaimed(true)
		}
	}

	const init = async () => {
		setStakeData(await getData())
	}

	useEffect(() => {
		init();
		console.log(stakeData, "stakeData")
	}, []);

	useEffect(() => {
		
		isClaimed();
	}, [isClaimed]);

	return (
		<div className="stakeboard-card">
			<h6 className="title">STAKE LEADERBOARD</h6>
			<div
				className="d-flex flex-wrap justify-content-center mb-2"
				style={{ gap: "10px", marginBottom: "15px" }}
			>
				<div className="stakeboard-card-item">
					<h6 className="subtext">Total Staked</h6>
					<h5 className="subtitle">--</h5>
				</div>
				<div className="stakeboard-card-item">
					<h6 className="subtext">Total Stakers</h6>
					<h5 className="subtitle">--</h5>
				</div>
			</div>
			<table className="table __table">
				<thead>
					<tr>
						<th>Position</th>
						<th className="text-center" style={{ width: "50%" }}>
							Address
						</th>
						<th className="text-end">Stake Amount</th>
					</tr>
				</thead>
				<tbody>
					{stakeData?.map((item, i) => (
						<tr key={i}>
							<td>{i + 1}.</td>
							<td>
								<div className="copy-address">
									<CopyValue val={item.id} />
								</div>
							</td>
							<td className="text-end">{((Number(ethers.utils.formatEther((item.stakeAmountMonth))).toFixed(2)) - (Number(ethers.utils.formatEther((item.stakeAmountMonth))).toFixed(2) * 0.0011)).toFixed()} ANT</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="mt-3 text-center pb-4">
			{/* <button onClick={claimHandler} className="cmn-btn" type="button">Load More</button>
<br></br><br></br> */}
{!claimed ? <button onClick={claimHandler} className="cmn-btn" type="button">
					Claim 10 ANT-P
				</button> : <button className="cmn-btn" type="button">
					 claimed
				</button>}
				
			</div>
		</div>
	);
};

export default StakeLeaderBoard;
