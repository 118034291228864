import React from "react";
import { Link } from "react-router-dom";
import {
	ChartIcon,
	GlobeFill,
	LockIcon,
	TelegramFill,
	TwitterFill,
	UserBookmark,
	UserCheck,
} from "../IconTheme";


const AntexSocialIcons = (links) => {
	const info =(links.links);
	// const socialData = [
	// 	{
	// 		icon: <TwitterFill />,
	// 		link: info.twitter,
	// 	},
	// 	{
	// 		icon: <TelegramFill />,
	// 		link: info.telegram,
	// 	},
	// 	{
	// 		icon: <GlobeFill />,
	// 		link: info.website,
	// 	},
	// ];

	const socialData = [
		{
			icon: <TwitterFill />,
			link: GlobeFill,
		},
		{
			icon: <TelegramFill />,
			link: GlobeFill,
		},
		{
			icon: <GlobeFill />,
			link: GlobeFill,
		},
	];
	
	return (
		<ul className="antex-social">
			{socialData?.map(({ icon, link }, i) => (
				<li key={i}>
					<a href={link}>{icon}</a>
				</li>
			))}
		</ul>
	);
};

export default AntexSocialIcons;
