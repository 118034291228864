import { ethers } from "ethers";
import { stakingABI } from "../abi/staking";


export const getStakingBalance = async (address) => {
    try{
        const provider = new ethers.providers.JsonRpcProvider('https://testnet.hepton.io');
        const contract = new ethers.Contract("0x2B5eDdd4402132e2a2004C665a5b071fA3c2abb7", stakingABI, provider);
        const balance = await contract.stakes(address);
        return ethers.utils.formatEther(balance);
    } catch (e){
        console.log(e);
        return 0;
    }
};

export const getTotalStaked = async (address) => {
    try{
        const provider = new ethers.providers.JsonRpcProvider('https://testnet.hepton.io');
        const contract = new ethers.Contract("0x2B5eDdd4402132e2a2004C665a5b071fA3c2abb7", stakingABI, provider);
        const balance = await contract.totalStaked();
        return ethers.utils.formatEther(balance);
    } catch (e){
        console.log(e);
        return 0;
    }
}

export const getEarnings = async (address) => {
    try{
        const provider = new ethers.providers.JsonRpcProvider('https://testnet.hepton.io');
        const contract = new ethers.Contract("0x2B5eDdd4402132e2a2004C665a5b071fA3c2abb7", stakingABI, provider);
        const balance = await contract.calculateEarnings(address);
        const balance2 = await contract.stakeRewards(address);
        let bal1 = ethers.utils.formatEther(balance);
        let bal2 = ethers.utils.formatEther(balance2);
        let bal = (parseInt(bal1) +  parseInt(bal2))
        return bal.toFixed(2);

    } catch (e){
        console.log(e);
        return 0;
    }
}
