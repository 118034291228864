import React from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/img/banner-bg.png";
const Banner = () => {
	return (
		<section
			className="banner-section"
			style={{ background: `url(${img}) no-repeat center center / cover` }}
		>
			<div className="container">
				<div className="banner-content">
					<h1 className="title">Decentralised Launchpad on HEPTON L2</h1>
					<div className="text">
						Providing innovative platform for fundraising on Hepton Layer
						2 Blockchain.
					</div>
					<div className="btn-grp">
						<a href="https://docs.antexpad.com" className="cmn-btn">
							DOCS
						</a>
						<Link to="staking" className="cmn-btn btn-outline">
							STAKE ANT-P
						</Link>
					</div>
					<div className="counter-wrapper">
						<div className="counter-item">
							<h5 className="count">0+</h5>
							<div>IDO Projects</div>
						</div>
						<div className="counter-item">
							<h5 className="count">$0,000,000</h5>
							<div>Fund Raised</div>
						</div>
						<div className="counter-item">
							<h5 className="count">28.6%</h5>
							<div>ANT STAKING ROI</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Banner;
