import React from "react";

const Progress = ({ title, progress, className, ...rest }) => {
	return (
		<div className={`progress ${className}`}>
			<div
				className="progress-bar text-start px-3"
				style={{ width: `${progress}%` }}
			>
				{title}
			</div>
		</div>
	);
};

export default Progress;
