import cogoToast from '@successtar/cogo-toast';
import "./Alert.css"
export const AlertConnectWallet = () => {
    cogoToast.success('Wallet Connected', {position : 'top-center', });
}
export const success = (msg) => {
    cogoToast.success(msg, {position : 'top-center', });
  }
export const info = (msg) => {
    cogoToast.info(msg, {position : 'top-center', });
}
export const error = (msg) => {
    cogoToast.error(msg, {position : 'top-center', });
}
export const loading = (msg) => {
    cogoToast.loading(msg, {position : 'top-center', hideAfter : 4});
}